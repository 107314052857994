import React, { useRef } from "react";
import "./styles.css";

const Card = ({
  children,
  onNext,
  onPrev,
  step,
  currentStep,
  validNext,
  disablePrev = false,
}) => {
  const cardRef = useRef();
  const oldStepRef = useRef(0);
  const valid = validNext?.() ?? true;

  const startAnimation = () => {
    cardRef.current.classList.add("animate");
    cardRef.current.addEventListener("animationend", removeAnimation);
  };

  const removeAnimation = (e) => {
    cardRef.current.classList.remove("animate");
    cardRef.current.removeEventListener("animationend", removeAnimation);
  };

  if (currentStep !== oldStepRef.current) {
    oldStepRef.current = currentStep;
    startAnimation();
  }

  return (
    <div
      ref={cardRef}
      className="card"
      data-active={currentStep === step}
      data-step={step}
      data-current-step={currentStep}
    >
      <div className="content">
        {step > 0 && (
          <button className="prev" onClick={onPrev} disabled={disablePrev}>
            <span className="material-symbols-outlined">arrow_left_alt</span>
          </button>
        )}
        {children}
        <button className="next" onClick={() => onNext()} disabled={!valid}>
          <span className="material-symbols-outlined">
            {step === 2 ? "done" : "arrow_right_alt"}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Card;
