import React from "react";
import { selectedTime, disabledIdxs } from "../../store/states";

const Chips = ({ label, options = [] }) => {
  const onSelectTime = (i) => {
    if (!disabledIdxs.value.includes(i)) selectedTime.value = +i;
  };

  return (
    <div className="input-view">
      <label>{label}</label>
      <div className="chip-group" tabIndex="-1" role="radiogroup">
        {options.map((o, i) => {
          const disabled = disabledIdxs.value.includes(i);
          const classes = `chip ${
            i === selectedTime.value && !disabled
              ? "active"
              : disabled
              ? "disabled"
              : ""
          }`;

          return (
            <div
              key={i}
              className={classes}
              tabIndex={i}
              role="radio"
              aria-checked={
                !disabled && selectedTime.value === i ? "true" : "false"
              }
              onClick={() => onSelectTime(i)}
            >
              <input type="radio" name="radioEx" value={i} />
              <span id="radioOneLabel">{o}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Chips;
