const ADD_DAYS = (date, days) => {
  let newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

//Gets Calendar Days
const GET_X_CALENDAR_DAYS = (startDate, numOfDays) => {
  let tempCalDays = [];
  for (let i = 0; i <= numOfDays + 1; i++)
    tempCalDays.push(ADD_DAYS(startDate, i));
  return tempCalDays;
};

const GEN_GUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export { ADD_DAYS, GET_X_CALENDAR_DAYS, GEN_GUID };
