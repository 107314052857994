import axios from "../config/axiosConfig";
import { queryParam } from "../store/stateMethods";
import { GEN_GUID } from "../constants/helpers";

const generatedOtpId = GEN_GUID();
const path = "/otp";

const requestOtp = async (email, name, action) => {
  const otpId = queryParam.bookingId || generatedOtpId;
  const otpObj = {
    email: email,
    otpId: otpId,
    name: name,
    action: action,
  };
  let res = await axios.post(path, otpObj);
  return res;
};

export { requestOtp, generatedOtpId };
