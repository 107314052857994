import React, { useRef, useEffect } from "react";
import "./styles.css";

const DisabledScreen = ({ open, children }) => {
  const modal = useRef();

  useEffect(() => {
    if (open) modal.current.showModal();
    else modal.current.close();
  }, [open]);

  return (
    <dialog className="disabledModal" ref={modal}>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </dialog>
  );
};

export default DisabledScreen;
