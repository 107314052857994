import { signal, computed } from "@preact/signals-react";
import { CURRENT_DATE } from "../constants/settings";
import { onStart } from "./stateMethods";

//Date
const selectedDate = signal(CURRENT_DATE);
const userInfo = signal(null);
const booked = signal(null);
const selectedTime = signal(null);
const disabledIdxs = computed(
  () => (booked.value ?? {})[`${selectedDate.value.getTime()}`] ?? []
);

const modalOpen = signal(false);
const formatedDate = computed(
  () =>
    `${selectedDate.value.toLocaleString("default", {
      weekday: "long",
    })}, ${selectedDate.value.toLocaleString("default", {
      month: "long",
    })} ${selectedDate.value.getDate()}`
);

onStart();

export {
  selectedDate,
  userInfo,
  booked,
  selectedTime,
  disabledIdxs,
  modalOpen,
  formatedDate,
};
