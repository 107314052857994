import React, { useEffect, useState, useRef, useCallback } from "react";
import Section from "../section/Section";
import Input from "../inputs/Input";
import Card from "../card/Card";
import { requestOtp } from "../../api/otpApi";

const OtpForm = ({ currentStep, onNext, onPrev, email, name, isUpdate }) => {
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(60); // 1 Min
  const interval = useRef(null);
  const otpSent = useRef(false);

  const sendOtp = useCallback(async () => {
    await requestOtp(email, name, isUpdate ? "update" : "confirm");
  }, [email, name, isUpdate]);

  useEffect(() => {
    if (interval.current === null && currentStep === 2) {
      interval.current = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    }

    if (!otpSent.current && currentStep === 2) {
      otpSent.current = true;
      sendOtp();
    }

    return () => {
      clearInterval(interval.current);
      interval.current = null;
    };
  }, [currentStep, sendOtp]);

  useEffect(() => {
    if (seconds === 0 && interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  }, [seconds]);

  const resendOtp = async () => {
    await sendOtp();
    setSeconds(180);
    if (interval.current === null) {
      interval.current = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    }
  };

  const onValueChange = (event) => {
    if (event) setCode(event.target.value.trim());
  };

  const validFields = () => {
    const isMissing = code.length !== 4;

    return !isMissing;
  };

  const submitForm = () => {
    if (validFields()) onNext(code);
  };

  return (
    <Card
      onNext={submitForm}
      currentStep={currentStep}
      step={2}
      onPrev={onPrev}
      validNext={validFields}
      disablePrev={seconds > 0}
    >
      <Section heading={"OTP Confirmation"}>
        <h2>Please enter the code</h2>
        <Input
          value={code}
          type={"text"}
          field={"code"}
          label={"OTP Code"}
          placeholder={"- - - -"}
          inputMode="numeric"
          autoComplete="one-time-code"
          onChange={onValueChange}
          maxLength="4"
          required
        />

        <button className="resend" onClick={resendOtp} disabled={seconds > 0}>
          {seconds > 0 ? (
            <span>{`Resend Code: ${parseInt(seconds / 60)}:${
              seconds % 60 < 10 ? "0" : ""
            }${seconds % 60}`}</span>
          ) : (
            <span className="material-symbols-outlined">rotate_left</span>
          )}
        </button>
      </Section>
    </Card>
  );
};

export default OtpForm;
