import React from "react";
import { Instagram } from "../../assets/svgs";

const SocialBlock = () => {
  return (
    <div className="social">
      <label>Check Our Latest Dresses</label>
      <div className="icons-box">
        <Instagram href={"https://www.instagram.com/vowsboutique/?hl=en"} />
      </div>
    </div>
  );
};

export default SocialBlock;
