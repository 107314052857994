import React from "react";
import Home from "./pages/Home";
import { queryParam } from "./store/stateMethods";
import { userInfo } from "./store/states";
import Delete from "./pages/Delete";
import Confirm from "./pages/Confirm";
import Dialog from "./components/section/Dialog";

const App = () => {
  const onCloseModal = () => {
    // modalOpen.value = false;
    window.location = window.location.pathname;
  };

  return (
    <div className="app">
      {queryParam.isDelete ? <Delete booking={userInfo.value} /> : <Home />}
      <Dialog closeModal={onCloseModal}>
        <Confirm />
      </Dialog>
    </div>
  );
};

export default App;
