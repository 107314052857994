import React, { useState } from "react";
import Input from "../inputs/Input";
import Section from "../section/Section";
import Card from "../card/Card";
import { userInfo } from "../../store/states";
import { queryParam } from "../../store/stateMethods";

const DEFAULT_INFO_VALUES = {
  name: "",
  email: "",
  phone: "",
};

const isUpdate = queryParam.action === "update" ? "update" : "";

const CustomerForm = ({ currentStep, onNext }) => {
  const [info, setInfo] = useState(userInfo.value || DEFAULT_INFO_VALUES);

  const onValueChange = (event) => {
    if (event)
      setInfo((prev) => ({
        ...prev,
        [event.target.name]: event.target.value.trim(),
      }));
  };

  const validFields = () => {
    const isEmpty = !info.name || !info.email || !info.phone;

    const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
      info.email
    );
    const validPhone =
      /^(((\+|00)?\d{1,4}|0)7[0-9]{8}|(\+|00)?[0-9]{12})$/.test(info.phone);

    return !isEmpty && validEmail && validPhone;
  };

  const nextPage = () => {
    if (validFields()) {
      userInfo.value = info;
      onNext();
    }
  };
  return (
    <Card
      onNext={nextPage}
      currentStep={currentStep}
      step={0}
      validNext={validFields}
    >
      <Section heading={"Bride's Information"}>
        <Input
          value={info.name}
          type={"text"}
          field={"name"}
          label={"Name"}
          placeholder={"Bride's Name"}
          onChange={onValueChange}
          oClass={isUpdate}
        />
        <Input
          value={info.email}
          type={"email"}
          field={"email"}
          label={"Email Address"}
          placeholder={"email@domain.com"}
          inputMode="email"
          onChange={onValueChange}
          oClass={isUpdate}
          message={"Confirmation code will be sent to this email"}
        />
        <Input
          value={info.phone}
          type={"tel"}
          field={"phone"}
          label={"Phone Number"}
          placeholder={"+[Code]xxxxxxxxx"}
          inputMode="numeric"
          onChange={onValueChange}
          oClass={isUpdate}
          message={"We will contact you on this number"}
        />
      </Section>
    </Card>
  );
};

export default CustomerForm;
