import React, { useRef, useState, useEffect } from "react";
import "./styles.css";
import QuoteBlock from "../section/QuoteBlock";
import QUOTES from "../../constants/quotes";

const Splash = ({ onSplashEnd }) => {
  const splashRef = useRef();
  const splashEndRef = useRef(onSplashEnd);
  const [next, setNext] = useState(false);
  const QUOTE_IDX = useRef(Math.floor(Math.random() * QUOTES.length));

  useEffect(() => {
    if (next) {
      setTimeout(() => {
        splashEndRef.current(true);
        setTimeout(() => {
          splashRef.current.style.display = "none";
        }, 1000);
      }, 2000);
    }
  }, [next]);

  return (
    <div className="splash" ref={splashRef}>
      <div className={`container ${next ? "go" : ""}`}>
        <QuoteBlock quote={QUOTES[QUOTE_IDX.current]} />
        <button onClick={() => setNext(true)}>
          <span className="material-symbols-outlined">arrow_right_alt</span>
        </button>
      </div>
    </div>
  );
};

export default Splash;
