import React from "react";
import logo from "../assets/logo2.png";
import { formatedDate, selectedTime } from "../store/states";
import { TIME_SLOTS } from "../constants/settings";
import SocialBlock from "../components/section/SocialBlock";
import { queryParam } from "../store/stateMethods";

const Confirm = () => {
  const newMessage =
    "Thank you for choosing Vows. Your booking has been confirmed!";
  const updateMessage =
    "Thank you for choosing Vows. Your booking has been updated successfully!";
  const deleteMessage =
    "Your journey to find the perfect dress is a special one, and whenever you're ready to continue, we'll be here with open arms.";

  const message = queryParam.isUpdate
    ? updateMessage
    : queryParam.isDelete
    ? deleteMessage
    : newMessage;

  return (
    <div className="confirm">
      <img src={logo} alt="logo" />
      <h1>{message}</h1>
      {!queryParam.isDelete && (
        <h3>
          <span>{formatedDate}</span> <br />
          <span>{TIME_SLOTS[selectedTime.value]}</span>
        </h3>
      )}

      <SocialBlock />
    </div>
  );
};

export default Confirm;
