import React from "react";
import "./styles.css";

const Input = ({
  field,
  label,
  placeholder,
  type,
  message,
  oClass = "",
  ...rest
}) => {
  return (
    <div className="input-view">
      <label htmlFor={field}>{label}</label>
      <input
        className={oClass}
        id={field}
        name={field}
        placeholder={placeholder}
        autoComplete="off"
        disabled={oClass !== ""}
        {...rest}
      ></input>
      {message && (
        <label className="message" htmlFor={field}>
          {message}
        </label>
      )}
    </div>
  );
};

export default Input;
