const QUOTES = [
  {
    q: "Marriage is not a noun; it's a verb. It isn't something you get. It's something you do. It's the way you love your partner every day.",
    a: "Barbara De Angelis",
  },
  {
    q: "A successful marriage requires falling in love many times, always with the same person.",
    a: "Mignon McLaughlin",
  },
  {
    q: "Love is the master key that opens the gates of happiness, of hatred, of jealousy, and, most easily of all, the gate of fear.",
    a: "Oliver Wendell Holmes, Sr.",
  },
  {
    q: "Marriage is the golden ring in a chain whose beginning is a glance and whose ending is eternity.",
    a: "Kahlil Gibran",
  },
  {
    q: "A happy marriage is a long conversation which always seems too short.",
    a: "André Maurois",
  },
  {
    q: "Every love story is beautiful, but ours is my favorite.",
    a: "Unknown",
  },
  {
    q: "A bride's beauty is her sincerity, her love, and the way she embraces her partner's heart.",
    a: "Unknown",
  },
  {
    q: "A bride's radiance comes not from her gown but from her joy and love within.",
    a: "Unknown",
  },
  {
    q: "The most beautiful thing a bride can wear is the confidence and happiness in her eyes.",
    a: "Unknown",
  },
  {
    q: "A wedding dress is both an intimate and personal for a woman - it must reflect the personality and style of the bride.",
    a: "Carolina Herrera",
  },
  {
    q: "A wedding dress is a story in lace, silk, and tulle, woven with love.",
    a: "Unknown",
  },
  {
    q: "The perfect wedding dress is the one that lets your inner light shine through.",
    a: "Unknown",
  },
  {
    q: "The wedding dress symbolizes a moment of pure, radiant, and elegant love.",
    a: "Unknown",
  },
  {
    q: "A wedding dress is a work of art, a representation of a bride's dreams and the promise of a beautiful future.",
    a: "Unknown",
  },
  {
    q: "Today is the day we say 'I do' and forever entwine our hearts.",
    a: "Unknown",
  },
  {
    q: "A wedding day is a moment in time when the soul dances with the heart.",
    a: "Unknown",
  },
  {
    q: "On this day, two souls become one, and the journey of love begins anew.",
    a: "Unknown",
  },
  {
    q: "A wedding day is a beautiful melody of love, promising harmony for a lifetime.",
    a: "Unknown",
  },
  {
    q: "A wedding day is the start of a lifelong symphony composed of love and happiness.",
    a: "Unknown",
  },
  {
    q: "Love is the secret ingredient that makes a marriage beautiful.",
    a: "Unknown",
  },
  {
    q: "Marriage is the moment two hearts find the melody that they will dance to for a lifetime.",
    a: "Unknown",
  },
  {
    q: "In marriage, the little things are the big things. It's never being too old to hold hands.",
    a: "Unknown",
  },
  {
    q: "Love is the master key that opens the gates of happiness and fulfillment.",
    a: "Oliver Wendell Holmes, Sr.",
  },
  {
    q: "Marriage is the golden ring in a chain whose beginning is a glance and whose ending is eternity.",
    a: "Kahlil Gibran",
  },
  {
    q: "A successful marriage requires falling in love many times, always with the same person.",
    a: "Mignon McLaughlin",
  },
  {
    q: "Love is the master key that opens the gates of happiness, of hatred, of jealousy, and, most easily of all, the gate of fear.",
    a: "Oliver Wendell Holmes, Sr.",
  },
  {
    q: "A wedding dress is a work of art, a representation of a bride's dreams and the promise of a beautiful future.",
    a: "Unknown",
  },
  {
    q: "The perfect wedding dress is the one that lets your inner light shine through.",
    a: "Unknown",
  },
  {
    q: "The wedding dress symbolizes a moment of pure, radiant, and elegant love.",
    a: "Unknown",
  },
  {
    q: "The most beautiful thing a bride can wear is the confidence and happiness in her eyes.",
    a: "Unknown",
  },
];

export default QUOTES;
