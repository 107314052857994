import { ADD_DAYS, GET_X_CALENDAR_DAYS } from "./helpers";

//Available Timings
const TIME_SLOTS = ["12 - 02 PM", "02 - 04 PM", "04 - 06 PM", "06 - 08 PM"];

const DAY_OFF = null; //NO DAYS OFF FOR NOW

const createUTCDate = () => {
  const today = new Date();
  return new Date(
    Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
  );
};

const iniCurrentDate = () => {
  const daysToAdd = new Date().getUTCHours() >= 9 ? 2 : 1;
  const firstDate = ADD_DAYS(createUTCDate(), daysToAdd);
  if (firstDate.getDay() === DAY_OFF) return ADD_DAYS(firstDate, 1);
  return firstDate;
};

const CURRENT_DATE = iniCurrentDate();

//Day off
const DAY_OFF_INDEX = () => {
  if (DAY_OFF === null) return DAY_OFF;
  const dayOffIdx = DAY_OFF;
  const todayIdx = CALENDAR_DAYS[0].getDay();
  if (todayIdx > dayOffIdx) {
    return 7 + todayIdx - dayOffIdx;
  }

  return 7 - (dayOffIdx - todayIdx);
};

//Calendar Days
const CALENDAR_DAYS = GET_X_CALENDAR_DAYS(CURRENT_DATE, 60);

export { TIME_SLOTS, CURRENT_DATE, DAY_OFF_INDEX, CALENDAR_DAYS };
