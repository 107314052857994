import React from "react";
import Section from "../section/Section";
import { TIME_SLOTS } from "../../constants/settings";
import Chips from "../inputs/Chips";
import {
  selectedDate,
  userInfo,
  selectedTime,
  disabledIdxs,
  formatedDate,
} from "../../store/states";
import Card from "../card/Card";

const BookingForm = ({ currentStep, onNext, onPrev }) => {
  const validFields = () => {
    const isEmpty = selectedTime.value === null;
    const isAlreadyBooked = disabledIdxs.value.includes(selectedTime.value);

    return !isEmpty && !isAlreadyBooked;
  };

  const submitForm = () => {
    if (validFields()) {
      const data = {
        date: selectedDate.value,
        time: selectedTime.value,
        ...userInfo.value,
      };
      onNext(data);
    }
  };

  return (
    <Card
      onNext={submitForm}
      currentStep={currentStep}
      step={1}
      onPrev={onPrev}
      validNext={validFields}
    >
      <Section heading={"Booking Information"}>
        <h2>{formatedDate}</h2>
        <Chips options={TIME_SLOTS} label={"Select Time"} />
      </Section>
    </Card>
  );
};

export default BookingForm;
