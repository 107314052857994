import * as bookingApi from "../api/bookingApi";
import { booked, selectedDate, selectedTime, userInfo } from "./states";

let retrievedBooking = null;

const searchParams = new URLSearchParams(document.location.search);
const queryParam = {
  action: searchParams.get("a"),
  bookingId: searchParams.get("id"),
  isUpdate: searchParams.get("a") === "update",
  isDelete: searchParams.get("a") === "delete",
};

const getBookings = async () => {
  const response = await bookingApi.getAllPublicBookings();
  if (response.status !== 200) return {};
  return response.data;
};

const getBookingById = async (bookingId) => {
  if (!bookingId) return;
  const response = await bookingApi.getBookingById(bookingId);
  if (response.status !== 200) return {};
  return response.data;
};

const addBooking = async (booking) => {
  const response = await bookingApi.addBooking(booking);
  if (response.status !== 200) return null;
  return response.data;
};

const updateBooking = async (newBooking) => {
  if (!queryParam.bookingId) return null;
  const body = {
    bookingId: retrievedBooking.id,
    oDate: retrievedBooking.date,
    oTime: retrievedBooking.time,
    nDate: newBooking.date,
    nTime: newBooking.time,
    otp: newBooking.otp,
    name: retrievedBooking.name,
    email: retrievedBooking.email,
  };
  const response = await bookingApi.updateBooking(body);
  if (response.status !== 200) return null;
  return response.data;
};

const cancelBooking = async (otp) => {
  const bookingObj = {
    bookingId: retrievedBooking.id,
    date: retrievedBooking.date,
    time: retrievedBooking.time,
    name: retrievedBooking.name,
    otp: otp,
    email: retrievedBooking.email,
  };
  const response = await bookingApi.cancelBooking(bookingObj);
  if (response.status !== 200) return null;
  return response.data;
};

const onStart = async () => {
  if (queryParam.action !== "cancel") booked.value = await getBookings();
  if (queryParam.action) {
    retrievedBooking = await getBookingById(queryParam.bookingId);
    if (retrievedBooking) {
      selectedDate.value = new Date(retrievedBooking.date);
      selectedTime.value = retrievedBooking.time;
      userInfo.value = {
        name: retrievedBooking.name,
        phone: retrievedBooking.phone,
        email: retrievedBooking.email,
      };
    }
  }
};

export { addBooking, updateBooking, onStart, cancelBooking, queryParam };
