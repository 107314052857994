import React, { useRef } from "react";
import { modalOpen } from "../../store/states";
import { queryParam } from "../../store/stateMethods";
import "./styles.css";

const Dialog = ({ closeModal, children }) => {
  const modal = useRef();
  const openedBefore = useRef(false);

  const onClose = () => {
    modal.current.classList.add("hide");
    modal.current.addEventListener("animationend", animateClose);
  };

  const animateClose = (e) => {
    modal.current.classList.remove("hide");
    modal.current.close();
    modal.current.removeEventListener("animationend", animateClose);
  };

  if (modalOpen.value) {
    modal.current.showModal();
    openedBefore.current = true;
  } else if (openedBefore.current) onClose();

  return (
    <dialog className="modal" ref={modal}>
      {queryParam.isDelete && (
        <div className="close">
          <button onClick={closeModal}>
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>
      )}
      {children}
    </dialog>
  );
};

export default Dialog;
