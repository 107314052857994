import axios from "../config/axiosConfig";
import { generatedOtpId } from "./otpApi";

const path = "/bookings";

const getAllPublicBookings = async () => {
  let res = await axios.get(path);
  return res;
};

const getBookingById = async (id) => {
  let res = await axios.get(`${path}/${id}`);
  return res;
};

const addBooking = async (bookingObj) => {
  const body = { ...bookingObj, otpId: generatedOtpId };
  let res = await axios.post(path, body);
  return res;
};

const updateBooking = async (updatedBookingObj) => {
  let res = await axios.put(path, updatedBookingObj);
  return res;
};

const cancelBooking = async (bookingObj) => {
  let res = await axios.delete(path, { data: bookingObj });
  return res;
};

export {
  getAllPublicBookings,
  getBookingById,
  addBooking,
  updateBooking,
  cancelBooking,
};
