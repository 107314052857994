import React from "react";
import { selectedDate } from "../../store/states";

const Day = ({ isFirst, disabled = false, date = new Date() }) => {
  const selected = selectedDate.value.getTime() === date.getTime();
  const classes = `day ${selected ? "selected" : ""} ${
    isFirst ? "first" : ""
  } ${disabled ? "disabled" : ""}`;

  const dateSelected = () => {
    if (!disabled) selectedDate.value = date;
  };

  return (
    <div className={classes} onClick={dateSelected}>
      <label className="date">{date.getDate()}</label>
      <label className="month">
        {date.toLocaleString("default", { weekday: "short" })}
      </label>
    </div>
  );
};

export default Day;
