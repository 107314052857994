import React, { Fragment } from "react";
import "./styles.css";
import Day from "./Day";
import { CALENDAR_DAYS, DAY_OFF_INDEX } from "../../constants/settings";

const Calendar = () => {
  const offIdx = DAY_OFF_INDEX();

  return (
    <div className="calendar">
      <div className="endFade" />
      {CALENDAR_DAYS.map((day, i) => {
        const newMonth =
          i === 0 || day.getMonth() !== CALENDAR_DAYS[i - 1].getMonth()
            ? day.toLocaleString("default", { month: "short" })
            : null;

        return (
          <Fragment key={`frag-${i}`}>
            {newMonth && (
              <div key={newMonth} className="stickyMonth">
                {newMonth}
              </div>
            )}
            <Day
              isFirst={i === 0}
              key={i}
              date={day}
              disabled={offIdx !== null && (i + offIdx) % 7 === 0}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default Calendar;
