import React, { useState } from "react";
import Splash from "../components/splash/Splash";
import "./styles.css";
import Header from "../components/header/Header";
import Calendar from "../components/calendar/Calendar";
import Form from "../components/form/Form";

const Home = () => {
  const [splashEnded, setSplashEnded] = useState(false);

  return (
    <div className="home">
      <Splash onSplashEnd={setSplashEnded} />
      {splashEnded && (
        <div className="container">
          <Header />
          <Calendar />
          <Form />
        </div>
      )}
    </div>
  );
};

export default Home;
