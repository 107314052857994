import React, { useState, useRef, useCallback, useEffect } from "react";
import logo from "../assets/logo2.png";
import SocialBlock from "../components/section/SocialBlock";
import Section from "../components/section/Section";
import Input from "../components/inputs/Input";
import { requestOtp } from "../api/otpApi";
import { cancelBooking } from "../store/stateMethods";
import { modalOpen } from "../store/states";
import DisabledScreen from "../components/section/DisabledScreen";

const Delete = ({ booking }) => {
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(60); // 1 Min
  const [isLoading, setIsLoading] = useState(false);
  const interval = useRef(null);
  const otpSent = useRef(false);

  const sendOtp = useCallback(async () => {
    if (booking?.email) {
      otpSent.current = true;
      await requestOtp(booking.email, booking.name, "cancel");
    }
  }, [booking]);

  useEffect(() => {
    if (!otpSent.current) {
      sendOtp();
    }

    if (interval.current === null) {
      interval.current = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval.current);
      interval.current = null;
    };
  }, [sendOtp]);

  useEffect(() => {
    if (seconds === 0 && interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  }, [seconds]);

  const resendOtp = async () => {
    await sendOtp();
    setSeconds(180);
    if (interval.current === null) {
      interval.current = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    }
  };

  const onValueChange = (event) => {
    if (event) setCode(event.target.value.trim());
  };

  const validFields = () => {
    const isMissing = code.length !== 4;

    return !isMissing;
  };

  const submitForm = async () => {
    if (validFields()) {
      setIsLoading(true);
      const response = await cancelBooking(code);
      setIsLoading(false);
      if (response) {
        modalOpen.value = true;
      }
    }
  };

  return (
    <>
      <DisabledScreen open={isLoading} />
      <div className="confirm">
        <img src={logo} alt="logo" />
        <h1>Booking Cancelation</h1>
        <Section heading={"OTP Code"}>
          <h2>Please enter the code to cancel booking</h2>
          <Input
            value={code}
            type={"text"}
            field={"code"}
            // label={"OTP Code"}
            placeholder={"- - - -"}
            inputMode="numeric"
            autoComplete="one-time-code"
            onChange={onValueChange}
            maxLength="4"
            required
          />

          <button className="resend" onClick={resendOtp} disabled={seconds > 0}>
            {seconds > 0 ? (
              <span>{`Resend Code: ${parseInt(seconds / 60)}:${
                seconds % 60 < 10 ? "0" : ""
              }${seconds % 60}`}</span>
            ) : (
              <span className="material-symbols-outlined">rotate_left</span>
            )}
          </button>

          <button
            className="submit"
            onClick={submitForm}
            disabled={code.length !== 4}
          >
            Cancel Booking
          </button>
        </Section>

        <SocialBlock />
      </div>
    </>
  );
};

export default Delete;
