import React from "react";
import "./styles.css";

const Section = ({ heading, children }) => {
  return (
    <div className="section">
      <h1>{heading}</h1>
      <div className="children">{children}</div>
    </div>
  );
};

export default Section;
