import React from "react";
import Logo from "../../assets/logo2.png";
import "./styles.css";

const Header = () => {
  return (
    <div className="header">
      <img src={Logo} alt="Vows Logo" />

      <label>
        We invite you to explore our exquisite collection and find the dress of
        your dreams.
      </label>
    </div>
  );
};

export default Header;
