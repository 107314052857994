import React, { useState } from "react";
import "./styles.css";
import CustomerForm from "./CustomerForm";
import BookingForm from "./BookingForm";
import { modalOpen } from "../../store/states";
import {
  addBooking,
  queryParam,
  updateBooking,
} from "../../store/stateMethods";
import OtpForm from "./OtpForm";
import DisabledScreen from "../section/DisabledScreen";

const Form = () => {
  const [current_step, setCurrentStep] = useState(0);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onNext = async (formData) => {
    if (formData) setData(formData);
    setCurrentStep((prev) => prev + 1);
  };

  const formatPhone = (phone) => {
    if (!phone) return null;
    let formattedPhone = phone;
    if (formattedPhone.startsWith("+"))
      formattedPhone = formattedPhone.slice(1, formattedPhone.length);
    if (formattedPhone.startsWith("00"))
      formattedPhone = formattedPhone.slice(2, formattedPhone.length);
    if (formattedPhone.startsWith("0"))
      formattedPhone = `962${formattedPhone.slice(1, formattedPhone.length)}`;
    return formattedPhone;
  };

  const onDone = async (otp) => {
    const bookingObj = {
      ...data,
      date: data.date.getTime(),
      otp: otp,
      phone: formatPhone(data.phone),
    };
    setIsLoading(true);

    let response = null;
    switch (queryParam.action) {
      case "cancel":
        break;
      case "update":
        response = await updateBooking(bookingObj);
        break;

      default:
        response = await addBooking(bookingObj);
        break;
    }
    setIsLoading(false);

    if (response) {
      setCurrentStep(0);
      modalOpen.value = true;
    }
  };

  return (
    <>
      <DisabledScreen open={isLoading} />
      <div className="formCol">
        <CustomerForm currentStep={current_step} onNext={onNext} />
        <BookingForm
          currentStep={current_step}
          onNext={onNext}
          onPrev={() => setCurrentStep(0)}
        />
        <OtpForm
          currentStep={current_step}
          onNext={onDone}
          onPrev={() => setCurrentStep(1)}
          email={data?.email}
          name={data?.name}
          isUpdate={queryParam.action === "update"}
        />
      </div>
    </>
  );
};

export default Form;
